<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :times="times"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="times">
      <img src="@/assets/speaial_activity_img/image/tentitle.png" class="title" alt="">
    </TitleAndTime>
    <div class="main flex">
      <div class="left">
        <img src="@/assets/speaial_activity_img/image/map_bg.png" alt="">
      </div>
      <div class="right">
        <div class="sub-title flex ai-c">
          <img class="icon-title" src="@/assets/speaial_activity_img/image/icon.png">
          <p>全国销售额TOP10城市</p>
        </div>
        <div class="bar-view">
          <BarChartY :data="barData"></BarChartY>
        </div>
      </div>
    </div>
    <Annotation></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import BarChartY from '@/components/Big/BarChartY'
import TitleAndTime from '@/components/Big/TitleAndTime'
import Annotation from '@/components/Big/Annotation'
const _config = {
  bigData: 0,
  color: [
    '#ff9900',
    '#fcb907',
    '#d8d058',
    '#fc622b',
    '#d74833',
    '#da4c6d',
    '#c27ba1',
    '#61649c',
    '#62acc0',
    '#72b48b',
  ],
  zhuDataX: [],
  mapChangeEn: [],
}
export default {
  components: {
    Headers,
    BarChartY,
    TitleAndTime,
    Annotation,
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      selProList: [],
      times: '',
      barData: {},
      mapData: [],
    }
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.init()
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (
        JSON.stringify(this.data) == '{}' ||
        (!this.data.cityRank && this.data.cityRank.length == 0)
      ) {
        return
      }
      let cityRank = this.data.cityRank
      this.times = cityRank[0].time + '数据时段'
      //设置右侧柱状图
      let dataX = []
      let dataY = []
      cityRank.forEach((item, index) => {
        dataX.push(item.city)
        dataY.push(item.sales)
      })
      _config.zhuDataX = []
      _config.mapChangeEn = []
      _config.zhuDataX = dataX
      this.barData = { dataX, dataY }
      //设置地图
      let mapData = []
      cityRank.forEach((item, index) => {
        mapData.push({
          name: item.city,
          value: index + 1,
        })
        _config.mapChangeEn.push({
          en: item.cityEn,
          zn: item.city,
        })
        //设置一个最大值，用来设置圆点的大小
        if (Number(item.sales) > _config.bigData) {
          _config.bigData = Number(item.sales)
        }
      })
      this.mapData = mapData
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
